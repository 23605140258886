import React from 'react'
import { injectIntl } from 'react-intl'
import { graphql } from 'gatsby'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Header'
import Summary from '../scenes/Documentation/Summary'
import Footer from '../scenes/Footer'

import backgroundPicture from '../img/artworks/artwork_background_grid_light.png'
import headerPicture from '../img/artworks/artwork_documenation_header.png'

const Documentation = ({ intl: { formatMessage }, data }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.documentation.title' })}
      metaDescription={formatMessage({ id: 'meta.documentation.description' })}
      metaOGImage={headerPicture}
    />
    <Navigation />
    <Header
      crumbs={[
        { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
        { name: formatMessage({ id: 'breadcrumb.documentation' }), path: '/documentation' },
      ]}
      backgroundPicture={backgroundPicture}
      artworkPicture={data.headerPicture.childImageSharp.fluid}
      artworkWidth={440}
      title={formatMessage({ id: 'documentation.header.title' })}
      description={formatMessage({ id: 'documentation.header.description' })}
    />
    <Space type="giant" />
    <Summary data={data} />
    <Space type="giant" />
    <Footer />
  </Layout>
)

export const pageQuery = graphql`
  query ($locale: String!) {
    headerPicture: file(relativePath: { eq: "artworks/artwork_documenation_header.png" }) {
      ...fluidImage
    }
    dataManagement: markdownRemark(
      frontmatter: { 
        lang: { eq: $locale } 
        name: { eq: "data-management"}
      }
    ) {
      html
    }
    dataProtection: markdownRemark(
      frontmatter: { 
        lang: { eq: $locale } 
        name: { eq: "data-protection"}
      }
    ) {
      html
    }
    privacyPolicy: markdownRemark(
      frontmatter: { 
        lang: { eq: $locale } 
        name: { eq: "privacy-policy"}
      }
    ) {
      html
    }
  }
`

export default withContext(injectContext(withIntl(injectIntl(Documentation))))
