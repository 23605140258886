import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system'

import Space from '../../../components/Space'

import Chapter from './components/Chapter'

import {
  getFirstElementByTagName,
  getElementsByTagName,
} from '../../../../utils/html'

const getTitleAndLinks = (content) => {
  let title
  const titleElement = getFirstElementByTagName(content, 'h1')
  if (titleElement) {
    title = titleElement.innerText
  }

  let links
  const subTitleElements = getElementsByTagName(content, 'h2')
  if (subTitleElements || subTitleElements.length > 0) {
    links = subTitleElements.map(element => ({
      id: element.lastElementChild && element.lastElementChild.id,
      title: element.innerText,
    }))
  }

  return {
    title,
    links,
  }
}

class Summary extends Component {

  constructor(props) {
    super(props)

    this.state = {
      dataManagement: {},
      dataProtection: {},
      privacyPolicy: {},
    }
  }

  componentDidMount() {
    const {
      data,
    } = this.props

    const dataManagement = getTitleAndLinks(data.dataManagement.html)
    const dataProtection = getTitleAndLinks(data.dataProtection.html)
    const privacyPolicy = getTitleAndLinks(data.privacyPolicy.html)

    this.setState({
      dataManagement,
      dataProtection,
      privacyPolicy,
    })
  }

  // Render
  render() {
    const {
      dataManagement,
      dataProtection,
      privacyPolicy,
    } = this.state

    return (
      <Container>
        <Row>
          <Col xs={12} md={4} offset={{ md: 1 }}>
            <Chapter
              path={'/data-management'}
              title={dataManagement.title}
              subtitles={dataManagement.links}
            />
            <Hidden xs>
              <Space type="l" />
            </Hidden>
            <Visible xs>
              <Space type="xl" />
            </Visible>
            <Chapter
              path={'/data-protection'}
              title={dataProtection.title}
              subtitles={dataProtection.links}
            />
          </Col>
          <Col xs={12} md={5} offset={{ md: 1 }}>
            <Visible xs>
              <Space type="xl" />
            </Visible>
            <Chapter
              path={'/privacy-policy'}
              title={privacyPolicy.title}
              subtitles={privacyPolicy.links}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default injectIntl(Summary)
