import './style.css'
import React, { Component } from 'react'
import { Hidden } from 'react-grid-system'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import I18nLink from '../../../../../components/I18nLink'
import Image from '../../../../../components/Image'
import Inline from '../../../../../components/Inline'
import Space from '../../../../../components/Space'

import circleArrowRightPicture from '../../../../../img/icons/icon_circle_arrow_right_green.svg'

const chapterContainerClass = classNames({
  'documentation-summary-chapter-container': true,
})
const titleClass = classNames({
  'documentation-summary-chapter-title': true,
})

class Chapter extends Component {

  render() {
    const {
      subtitles,
    } = this.props

    return (
      <div className={chapterContainerClass}>
        {this.renderTitle()}
        <Space type="s" />
        <ul>
          {subtitles.map(this.renderSubtitle)}
        </ul>
      </div>
    )
  }

  renderTitle = () => {
    const {
      path,
      title,
    } = this.props

    return (
      <span className={titleClass}>
        <I18nLink to={path}>
          {title}
          <Hidden xs>
            <Inline type="xxs" />
            <Image
              src={circleArrowRightPicture}
              alt="circle arrow right"
              height="20"
            />
          </Hidden>
        </I18nLink>
      </span>
    )
  }

  renderSubtitle = (subtitle, index) => {
    const {
      path,
    } = this.props
    return (
      <li key={`subtitle_${index}`}>
        <I18nLink
          to={`${path}#${subtitle.id}`}
        >
          {subtitle.title}
        </I18nLink>
      </li>
    )
  }
}

Chapter.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
}

Chapter.defaultProps = {
  subtitles: [],
}

export default Chapter
